import React, { ReactElement, useEffect, useState } from 'react';

import {
  Wrap,
  HStack,
  Image,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Link,
  Box,
} from '@chakra-ui/react';

import Loader from '@modules/common-ui/components/global-loader';
import { landingPageStyles } from './styles';
import { ConditionSeparator } from '@app/modules/common-ui/components/condition-separator';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter';
import { SignUpForm } from '../sign-up/SignUp';
// import { useAppEntryParams } from '@modules/core/hooks';
import { LandingPageDataType } from '@app/modules/core/landingPages/interfaces';
import { useParams } from 'react-router';
import { useAppEntryParams } from '@app/modules/core/hooks';

// const familyCooking = require('@assets/images/familyMealPrep.jpg');
// const familySunset = require('@assets/images/familySunset.jpg');

function LandingPage(): ReactElement {
  const { landingURL } = useParams();
  const [pageContent, setPageContent] = useState<LandingPageDataType | null>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [err, setErr] = useState<string | undefined>();
  const appEntryParams = useAppEntryParams();

  useEffect(() => {
    fetch(`https://www.lved.com/dynamicLandingPages/${landingURL}.json`)
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        const targetLandingPage = jsonData as LandingPageDataType;
        // const targetLandingPage = pagesArray.find((page) => page.url === landingURL);
        setIsLoading(false);
        if (!targetLandingPage) {
          setErr('Unable to find the page you are looking for');
        } else {
          setPageContent(targetLandingPage);
          if (targetLandingPage.promoCode)
            appEntryParams.setPromoCodeManually(targetLandingPage.promoCode!);
          if (targetLandingPage.affiliateCode)
            appEntryParams.setAffiliateManually(targetLandingPage.affiliateCode!);
          setErr(undefined);
        }
      })
      .catch((error) => {
        setErr(error.message);
      });
  }, []);

  return (
    <VStack
      bg={pageContent?.pageBackgroundColor ?? 'white'}
      color={pageContent?.pageTextColor ?? 'black'}
    >
      <UnAuthHeader activeLink='' />
      <HStack h='100px' />
      {isLoading ? (
        <Loader />
      ) : (
        <ConditionSeparator
          condition={!err}
          target={
            <VStack w='100%'>
              <Wrap {...landingPageStyles.wrapContainer}>
                <VStack {...landingPageStyles.promoTextContainer}>
                  <ConditionSeparator
                    condition={(pageContent?.imageURL ?? '').endsWith('.mp4')}
                    target={
                      <Box
                        as='video'
                        muted
                        autoPlay
                        loop
                        borderRadius={pageContent?.imageBorderRadius ?? 0}
                        src={`https://${pageContent?.imageURL ?? ''}`}
                        poster='https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217'
                        objectFit='contain'
                      />
                    }
                    defaultTarget={
                      <Image
                        {...landingPageStyles.welcomeImage}
                        borderRadius={pageContent?.imageBorderRadius ?? 0}
                        src={`https://${pageContent?.imageURL ?? ''}`}
                      />
                    }
                  />
                  <Text {...landingPageStyles.promoTagLine}>
                    {pageContent?.tagLine ?? '<tagLine>'}
                  </Text>
                  {pageContent?.subTagline && <Text>{pageContent!.subTagline!}</Text>}
                  {pageContent?.preFeatureContent &&
                    pageContent!.preFeatureContent!.map((content) => {
                      return <Text key={content}>{content}</Text>;
                    })}
                  <Text {...landingPageStyles.promoTextHighlight}>
                    {pageContent?.featureHeading ?? '<featureHeading>'}
                  </Text>
                  {pageContent?.features && (
                    <UnorderedList {...landingPageStyles.benefitsList}>
                      {pageContent?.features?.map((feature) => {
                        return <ListItem key={feature}>{feature}</ListItem>;
                      })}
                    </UnorderedList>
                  )}
                  {pageContent?.postFeatureContent &&
                    pageContent!.postFeatureContent!.map((content) => {
                      return <Text key={content}>{content}</Text>;
                    })}
                  <Text>{pageContent?.summary ?? '<summary>'}</Text>
                  <Text>{pageContent?.callToAction ?? '<callToAction>'}</Text>
                </VStack>
                <SignUpForm
                  linkColor={pageContent?.signupLinkColor}
                  transparentBackground
                  showLogo={false}
                />
              </Wrap>
              {pageContent?.faqs && (
                <VStack width='100%' padding='8px'>
                  <Text {...landingPageStyles.faqTitle}>FREQUENTLY ASKED QUESTIONS</Text>
                  <VStack {...landingPageStyles.faqListContainer}>
                    {pageContent!.faqs!.map((faq) => {
                      return (
                        <div key={faq.question}>
                          <Text {...landingPageStyles.faqQuestion} key={faq.question}>
                            {faq.question}
                          </Text>
                          <Text {...landingPageStyles.faqAnswer} key={faq.answer}>
                            {faq.answer}
                          </Text>
                        </div>
                      );
                    })}
                  </VStack>
                  <Link href='https://app.lved.com/faq'>
                    <Text color={pageContent?.signupLinkColor ?? 'brand.green.100'}>
                      Visit our FAQ page for more
                    </Text>
                  </Link>
                </VStack>
              )}
              {!pageContent?.faqs && (
                <Link href='https://app.lved.com/faq'>
                  <Text color={pageContent?.signupLinkColor ?? 'brand.green.100'}>
                    Visit our FAQ page for more information
                  </Text>
                </Link>
              )}
            </VStack>
          }
          defaultTarget={
            <VStack h='100vh'>
              <Text {...landingPageStyles.errorText}>
                There was an error retrieving this landing page. Please check the URL and try again.
              </Text>
            </VStack>
          }
        />
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default LandingPage;
